import { useEffect, useState } from "react";

import { type TransformMethod, useCollectionDataOnce } from "@doitintl/models-firestore";
import { type FirebaseCollectionReferenceModel, type FirebaseQueryModel } from "@doitintl/models-firestore/src/core";
import { type DocumentData } from "firebase/firestore";
import noop from "lodash/noop";

import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";

function getFieldFromFirstItem<T extends object, K extends keyof T>(
  collection: T[] | undefined,
  field: K,
  defaultValue: T[K]
): T[K] {
  return collection?.[0]?.[field] ?? defaultValue;
}

function getFieldFromLastItem<T extends object, K extends keyof T>(
  collection: T[] | undefined,
  field: K,
  defaultValue: T[K]
): T[K] {
  return collection?.[collection?.length - 1]?.[field] ?? defaultValue;
}

export function usePaginatedCollectionData<
  TModel extends DocumentData,
  TQueryField extends string & keyof TModel,
  TTransformed extends { [key in TQueryField]: TModel[TQueryField] },
>(
  query: FirebaseCollectionReferenceModel<TModel> | undefined,
  transform: TransformMethod<TModel, TTransformed>,
  options: {
    queryField: TQueryField;
    transformedQueryField?: TQueryField;
    limit?: number;
    // when provided, this field should contain a list of results matching the expected outcome from the queryField e.g
    // if the query field was the serviceName then the list could contain services like "AWS Lambda". any matching items
    // will be shown in the popular items section
    popularItems?: string[];
  }
) {
  const transformedQueryField = options.transformedQueryField ?? options.queryField;
  const limit = options.limit ?? 25;
  const showErrorSnackbar = useErrorSnackbar();
  const popularItems = options.popularItems || [];

  const [cursor, setCursor] = useState<{ direction: "<" | ">"; value: string; currentPage: number }>({
    direction: ">",
    value: "",
    currentPage: 0,
  });

  let paginatedQuery: FirebaseQueryModel<TModel> | undefined = query;
  let popularItemsQuery: FirebaseQueryModel<TModel> | undefined = undefined;

  const shouldLoadPopularItems = popularItems.length > 0;

  if (paginatedQuery) {
    if (shouldLoadPopularItems && !popularItemsQuery) {
      popularItemsQuery = paginatedQuery.where(options.queryField, "in", popularItems);
    }

    paginatedQuery = paginatedQuery
      .where(options.queryField, cursor.direction, cursor.value)
      .orderBy(options.queryField);

    if (cursor.direction === ">") {
      paginatedQuery = paginatedQuery.limit(limit);
    } else {
      paginatedQuery = paginatedQuery.limitToLast(limit);
    }
  }

  useEffect(() => {
    setCursor({ direction: ">", value: "", currentPage: 0 });
  }, [query?.path]);

  const [result, loading, error] = useCollectionDataOnce(paginatedQuery, {
    transform,
  });

  const [popularItemsResult, popularItemsLoading, _] = useCollectionDataOnce(popularItemsQuery, {
    transform,
  });

  useEffect(() => {
    if (error) {
      showErrorSnackbar(error.message);
      consoleErrorWithSentry(error);
    }
  }, [error, showErrorSnackbar]);

  const [lastItemWrapper, loadingLastPageCheck, lastPageError] = useCollectionDataOnce(
    query ? query.orderBy(options.queryField).limitToLast(1) : undefined,
    { transform }
  );
  useEffect(() => {
    if (lastPageError) {
      showErrorSnackbar(lastPageError.message);
      consoleErrorWithSentry(lastPageError);
    }
  }, [lastPageError, showErrorSnackbar]);

  const isLoading = loading || loadingLastPageCheck || (shouldLoadPopularItems && popularItemsLoading);

  const loadNext = isLoading
    ? noop
    : () => {
        setCursor(({ currentPage }) => ({
          direction: ">",
          value: getFieldFromLastItem(result as { [key in TQueryField]: string }[], transformedQueryField, ""),
          currentPage: currentPage + 1,
        }));
      };

  const loadPrev = isLoading
    ? noop
    : () => {
        setCursor(({ currentPage }) => ({
          direction: "<",
          value: getFieldFromFirstItem(result as { [key in TQueryField]: string }[], transformedQueryField, ""),
          currentPage: currentPage - 1,
        }));
      };

  const disablePrev = !query || cursor.currentPage === 0;
  const disableNext =
    !query ||
    (result !== undefined &&
      (result.length < limit ||
        (result.length > 0 &&
          getFieldFromFirstItem(lastItemWrapper as { [key in TQueryField]: string }[], transformedQueryField, "") ===
            getFieldFromLastItem(result as { [key in TQueryField]: string }[], transformedQueryField, ""))));

  return [result, popularItemsResult, isLoading, loadPrev, loadNext, disablePrev, disableNext] as const;
}
