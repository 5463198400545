import { type Dispatch, type FC, useId } from "react";

import { type ApiServiceDescriptor, CloudflowEngine } from "@doitintl/cmp-models";
import { getCollection, type TransformMethod } from "@doitintl/models-firestore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CircularProgress, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from "@mui/material";

import { type ProviderFilter, type ServiceFilter } from "./useActionSearchDialogStateMachine";
import { usePaginatedCollectionData } from "./usePaginatedCollectionData";

const label = "Service";
const emptyValueId = "~~empty~~";

const transformServices: TransformMethod<ApiServiceDescriptor, ServiceFilter & { serviceName: string }> = (
  { serviceName, latestVersion },
  { id }
) => ({
  serviceName,
  id,
  name: serviceName,
  version: latestVersion,
});

export const ActionSearchServiceFilter: FC<{
  emptyLabel: string;
  provider: ProviderFilter;
  value: ServiceFilter | undefined;
  onChange: Dispatch<ServiceFilter | undefined>;
}> = ({ emptyLabel, provider, value, onChange }) => {
  const labelId = useId();

  const [services, _, loadingServices, loadPrevPage, loadNextPage, disablePrevPage, disableNextPage] =
    usePaginatedCollectionData(
      getCollection(CloudflowEngine).doc("integrations").collection("apis").doc(provider.id).collection("services"),
      transformServices,
      {
        queryField: "serviceName",
      }
    );

  return (
    <FormControl sx={{ width: 300 }} size="small">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        value={value?.id ?? emptyValueId}
        label={label}
        onChange={({ target: { value } }) => {
          onChange(value === emptyValueId ? undefined : services?.find(({ id }) => id === value));
        }}
      >
        <MenuItem value={emptyValueId}>{emptyLabel}</MenuItem>
        {value && !services?.find(({ id }) => id === value?.id) && (
          <MenuItem key={value.id} value={value.id}>
            {value.name}
          </MenuItem>
        )}
        <Divider />
        {services?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
        {loadingServices && !services && (
          <Stack height={144} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
        {!loadingServices && !(disablePrevPage && disableNextPage) && (
          <>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="flex-end" pr={2}>
              <IconButton onClick={loadPrevPage} disabled={disablePrevPage} size="small">
                <ChevronLeftIcon />
              </IconButton>
              <IconButton onClick={loadNextPage} disabled={disableNextPage} size="small">
                <ChevronRightIcon />
              </IconButton>
            </Stack>
          </>
        )}
      </Select>
    </FormControl>
  );
};
