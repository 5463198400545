import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { Box, Container, Stack, Typography, useTheme } from "@mui/material";

import DoitLogo from "../../assets/doit-logo-hero-square.svg";
import { AvaIcon } from "../../Components/Ava/Messenger/AvaIcon";
import { AvaOpeningSource, useAvaDialogContext } from "../../Components/Ava2/AvaDialogContextProvider";
import { useIsFeatureEntitled, useIsFeatureEntitledMultiple } from "../../Context/TierProvider";
import { ThemeModes } from "../../muiThemeTypes";
import { entitlementsToContactSupport } from "../consts";
import { HeaderCard } from "./HeaderCard";

type Props = {
  onClickOpenRequest: () => void;
  orgClassification: string;
  customerClassification?: string;
};

export const HeaderCards = ({ onClickOpenRequest, orgClassification, customerClassification }: Props) => {
  const { handleOpenAvaDialog } = useAvaDialogContext();

  const isSupportChatWithAva = useIsFeatureEntitled("support:ticket:chat-with-ava");
  const { t } = useTranslation("services");

  const checkEntitlementsToContactSupport = useIsFeatureEntitledMultiple(entitlementsToContactSupport);

  const supportCardHeaderSubtitle = useMemo(() => {
    if (isSupportChatWithAva) {
      return t("HeaderCards.createRequestCard.subtitle.avaTicket");
    }
    return Object.keys(checkEntitlementsToContactSupport).some((key) => checkEntitlementsToContactSupport[key])
      ? t("HeaderCards.createRequestCard.subtitle.unlimited")
      : t("HeaderCards.createRequestCard.subtitle.restricted");
  }, [checkEntitlementsToContactSupport, isSupportChatWithAva, t]);

  const theme = useTheme();

  const avaSubtitle = (
    <Typography
      data-cy="avaParagraph"
      sx={{
        pb: 1,
        whiteSpace: "pre-line",
      }}
    >
      {t("HeaderCards.askAvaCard.subtitle")}
    </Typography>
  );

  return (
    <Stack sx={{ backgroundColor: theme.palette.mode === ThemeModes.LIGHT ? "#FAFAFA" : "", mx: -2, mt: -1, py: 5 }}>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          px: "2.5rem",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: 500,
            pb: 2.5,
          }}
        >
          {t("HeaderCards.title")}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <HeaderCard
            onClick={() => {
              handleOpenAvaDialog(AvaOpeningSource.SUPPORT);
            }}
            icon={<AvaIcon />}
            title={t("HeaderCards.askAvaCard.title")}
            subtitle={avaSubtitle}
            textButton={t("HeaderCards.askAvaCard.button")}
            buttonVariant="contained"
            dataCy="askAvaButton"
          />
          <HeaderCard
            onClick={onClickOpenRequest}
            icon={
              <Box
                sx={{
                  width: "25px",
                  height: "25px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={DoitLogo}
                  sx={{
                    height: "35px",
                    width: "35px",
                  }}
                />
              </Box>
            }
            title={t("HeaderCards.createRequestCard.title")}
            subtitle={<Typography data-cy="headerCardSubtitle">{supportCardHeaderSubtitle}</Typography>}
            textButton={t("HeaderCards.createRequestCard.button")}
            buttonVariant="outlined"
            dataCy="createTicketButton"
            disabled={orgClassification === "blocked" || customerClassification === "terminated"}
          />
        </Stack>
      </Container>
    </Stack>
  );
};
