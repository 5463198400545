import { useState } from "react";

import Grid from "@mui/material/Grid2";
import { Container, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import { BottomAppBar } from "@client/src/Components/BottomAppBar";
import { useAWSAccounts } from "@client/src/Pages/Settings/AWS/hooks";
import { useCustomerContext } from "@client/src/Context/CustomerContext";
import { useApiContext } from "@client/src/api/context";
import { consoleErrorWithSentry } from "@client/src/utils";

import cdAppSettings1 from "@cdAppSettings";

// TODO: Move to ENV
export const cdAppSettings = cdAppSettings1;

export const importAWSAccountCD = async ({ api, account, customer, setIsImporting, handleCancel }) => {
  if (!account?.id || !customer?.id) {
    consoleErrorWithSentry("Wrong account or customer");
    return;
  }

  setIsImporting(true);

  try {
    let { data } = await api.request({
      method: "post",
      baseURL: cdAppSettings.baseUrl,
      url: "/cloud-diagrams/api/scheme/account-import",
      params: {
        customer: customer.id,
      },
      data: {
        account: account.accountId,
        customer: customer.id,
      },
    });

    if (!data?._id || !data?.statussheet?.[0]?.ssid) {
      consoleErrorWithSentry("Something went wrong");
      return;
    }

    let newUrl = `/cloud-diagrams/scheme/${data._id}/${data.statussheet[0].ssid}/${customer.id}`;
    window.open(newUrl);
  } catch (err) {
    consoleErrorWithSentry(err);
  }

  setIsImporting(false);
  handleCancel();
};

// TODO: Remove this component
const CloudDiagramsImport = ({ cancelImport }) => {
  const [selectedAccount, setSelectedAccount] = useState();
  const [isImporting, setIsImporting] = useState();

  const [accounts] = useAWSAccounts();
  const { customer } = useCustomerContext();
  const api = useApiContext();

  const handleSelectAccount = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedAccount(value);
  };

  const handleCreate = () => {
    let account = (accounts || []).filter((c) => c.accountId === selectedAccount)?.[0] || null;
    importAWSAccountCD({ api, account, customer, setIsImporting, handleCancel });
  };

  const handleCancel = () => {
    setSelectedAccount();
    cancelImport();
  };

  return (
    <>
      <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 4 }}>
        <Grid container direction="column" spacing={2} sx={{ maxWidth: 745, width: "100%" }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Select an AWS account to import
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="aws-account-label" shrink={true}>
                AWS account
              </InputLabel>
              <Select
                labelId="aws-account-label"
                displayEmpty
                defaultValue=""
                label="AWS account"
                onChange={handleSelectAccount}
              >
                <MenuItem value="" disabled>
                  Select an AWS account
                </MenuItem>
                {accounts
                  .filter((c) => c.status === "Healthy")
                  .map(({ accountId }) => (
                    <MenuItem key={accountId} value={accountId}>
                      {accountId}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <BottomAppBar
        disabled={!selectedAccount}
        handlePrimaryButtonClicked={handleCreate}
        primaryButtonName="Create diagram"
        secondaryButtonName="Cancel"
        handleSecondaryButtonClicked={handleCancel}
        loading={isImporting}
      />
    </>
  );
};

export default CloudDiagramsImport;
